import { Button, FormControl, FormControlLabel, Grid, LinearProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SendIcon from '@mui/icons-material/Send';
//import DatePicker from '@mui/lab/DatePicker';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Checkbox } from '@mui/material';
import DOMPurify from 'dompurify';
import React, { ChangeEvent } from 'react';
import { AuthContext, AuthKey, AuthKeyValue, AuthResultKey, AuthType, ExtendedAuthKey, ExtendedAuthKeyValue, GetLinkResponse, IsTosAcceptedRequest, SendTosAcceptedRequest, TosType } from '../../../../types/apimodel';
import { formatDate, getCustomizingID, log } from '../../../../util/util';
import { StylesProvider } from '@mui/styles';
import LinkedappBackend from '../../../../services/LinkedappBackend';
import { Box } from '@mui/system';
import { CID_RHEINLAND, CID_RHION } from '../../../../types/constants';
import { AppState } from '../../../../types/types';


interface MyProps {
    link: GetLinkResponse | null,
    linkId: string | null,
    updateAuthContext: any,
    html?: string | null,
    appState: AppState,

}
interface MyState {
    link?: GetLinkResponse | null,
    linkId: string | null,
    errors: string[] | null,
    needsAuth: boolean | false,
    authType: AuthType | null,
    message: string | null,
    birthdateError: string | null,
    postcodeError: string | null,
    inputPostcode: string,
    inputBirthdate: Date | null,
    finished: boolean | false,
    buttonBlocked: boolean | false,
    sendTanButtonVisible: boolean | false,
    tanMethod: string | null,
    tanInputVisible: boolean | false,
    tanSending: boolean | false,
    tan: string | null,
    tanSendingError: string | null,
    KNOWN_DATE_POSTCODE_QUERY?: string | null,
    tos?: boolean | false,
    tosReadonly?: boolean,
    tosAlreadyAccepted?: boolean
}

/**
 * Fragt zur Sicherheit dem Benutzer bekannte Daten ab
 * (erweiterte Authentifizierung angefordert).
 * 
 * Dies sind zur Zeit zwei Fälle, die hier implementiert sind (Validierung und Verpacken in Key-Value-Liste).
 * Die Keys sind dabei fest vordefiniert.
 * 
 */


const weiterbuttonMitVorausgefuelltenPlz = () => {
    const button = document.getElementById('authbuttonweiter');
    if (button) {
        button.click();
    } else {
        console.error('Button not found!');
    }
};

/* 
const [tosReadonly, setTosReadonly] = useState(false);
const [tos, setTos] = useState(false);
*/


const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

//Postcode validation
let regexPlz = '^[0-9]{5}$';
let regexpPlz = new RegExp(regexPlz);

class AuthRequired extends React.Component<MyProps, MyState> {
    private intervalId: NodeJS.Timeout | null = null;

    
    constructor(props: MyProps) {
        super(props);
        let needsAuth = (props.link && props.link.needsAuth) || false;
        let authType = (props.link && props.link.authType) || null;
        let message = (props.link && props.link.message) || null;

        let tanInputVisible: boolean = false;
        let tanSendingError: string | null = null;
        if (props.link?.authResult !== null && props.link?.authResult.key !== null
            && (AuthResultKey[AuthResultKey.TAN_RETRY] === String(props.link?.authResult.key))) {
            tanInputVisible = true;
            if (props.link?.authResult.message != null) {
                tanSendingError = props.link?.authResult.message;
            }
        }

        let KNOWN_DATE_POSTCODE_QUERY: string = new URLSearchParams(window.location.search).get('KNOWN_DATE_POSTCODE') || '';

        let buttonBlockedState =true;
        if ((AuthType[AuthType.KNOWN_DATE_POSTCODE] === String(authType)) && this.props.link?.tosAcceptanceRequired === true) {
            buttonBlockedState = true;
        } else {
            buttonBlockedState= (AuthType[AuthType.TAN] === String(authType));
        }
        
        
        
        this.state = {
            errors: [], link: props.link, linkId: props.linkId, needsAuth: needsAuth, authType: authType, message: message, inputPostcode: '',
            inputBirthdate: null, birthdateError: '', postcodeError: '', finished: false, buttonBlocked: buttonBlockedState,
            sendTanButtonVisible: false, tanSending: false, tanInputVisible: tanInputVisible, tan: '', tanMethod: null, tanSendingError: tanSendingError, KNOWN_DATE_POSTCODE_QUERY: KNOWN_DATE_POSTCODE_QUERY,
            tos: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onSelectTanMethod = this.onSelectTanMethod.bind(this);
        this.onTanChange = this.onTanChange.bind(this);
        this.onSendTan = this.onSendTan.bind(this);
        this.intervalId = null;
    }


    handleTosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ tos: event.target.checked});
       // this.validate(event); 


       if (event.target.checked && this.state.inputPostcode && regexpPlz.test(this.state.inputPostcode)) {
             this.setState({buttonBlocked : false});
        } else {
            this.setState({buttonBlocked : true});
        }
    }


    handleChange(event: any, field: string) {
        if (field === 'postcode') {
            let value = event.target.value;
            if (value !== null) {
                value = value.trim();
            }
            this.setState({ inputPostcode: value });
        }
    }

    onSelectTanMethod(event: any) {
        if (event.target.value !== null) {
            this.setState({ tanMethod: event.target.value, sendTanButtonVisible: true });
        } else {
            this.setState({ tanMethod: event.target.value, sendTanButtonVisible: false });
        }
    }

    onSendTan(event: any) {
        this.setState({ tanSending: true }, () => {
            if (this.props.linkId !== null && this.state.authType !== null) {
                let tanType: any = this.state.tanMethod;
                LinkedappBackend.sendTransactionConfirmation(this.props.linkId, tanType).then((response) => {
                    if (response.success === true) {
                        // senden
                        this.setState({ tanSending: false, tanInputVisible: true, tanSendingError: null });
                    } else {
                        this.setState({ tanSending: false, tanInputVisible: false, tanSendingError: "Beim Senden der TAN ist ein Fehler aufgetreten." });
                    }
                });
            }
        });
    }

    onTanChange(event: any) {
        if (event.target.value !== null) {
            let regex = '^[0-9a-zA-Z]{0,12}$';
            let regexUnblock = '^[0-9a-zA-Z]{6,12}$';
            let regexp = new RegExp(regex);
            let regexpUnblock = new RegExp(regexUnblock);
            if (regexp.test(event.target.value)) {
                //regexpUnblock.test(event.target.value);
                this.setState({ tan: event.target.value, buttonBlocked: !regexpUnblock.test(event.target.value) });
            }
        }
    }

    handleDateChange(date: Date | null) {
        this.setState({ inputBirthdate: date });
    };


    validate(event: any): Promise<boolean> {
        return new Promise((resolve) => {
            // Entweder validate() wurde über onBlur() vom Element aus aufgerufen, oder über den submit-button (dann alles validieren)
            let validateBirthdate: boolean = (event.target.id === '' || event.target.id === 'birthdate')
                && ((AuthType[AuthType.KNOWN_DATE_BIRTHDATE] === String(this.state.authType)) || (AuthType[AuthType.KNOWN_DATE_POSTCODE_AND_BIRTHDATE] === String(this.state.authType)));
            let validatePostcode: boolean = (event.target.id === '' || event.target.id === 'postcode')
                && ((AuthType[AuthType.KNOWN_DATE_POSTCODE] === String(this.state.authType)) || (AuthType[AuthType.KNOWN_DATE_POSTCODE_AND_BIRTHDATE] === String(this.state.authType)));
            let loginTosAccepted: boolean = (event.target.id === '' || event.target.id === 'loginTosAccepted')
                && ((AuthType[AuthType.KNOWN_DATE_POSTCODE] === String(this.state.authType)) && this.props.link?.tosAcceptanceRequired === true);




            if (validateBirthdate) {
                // Regex wird nicht als solche erkannt, Escape vor den Punkten ist aber nötig.
                // eslint-disable-next-line 
                if (!this.state.inputBirthdate || (this.state.inputBirthdate !== null && this.state.inputBirthdate.toString() === 'Invalid Date')) {
                    this.setState({ birthdateError: 'Bitte geben Sie ein Datum im Format TT.MM.JJJJ an.' }, () => { resolve(false); });
                } else {
                    this.setState({ birthdateError: null }, () => { resolve(true); });

                }
            }

            if (validatePostcode) {
                if (!this.state.inputPostcode || !regexpPlz.test(this.state.inputPostcode)) {
                    this.setState({ postcodeError: 'Bitte geben Sie eine 5-stellige Postleitzahl an.' }, () => { resolve(false); });
                } else {
                    this.setState({ postcodeError: null }, () => { resolve(true); });
                }
            }

            //Weiter Button blockieren
            if (((AuthType[AuthType.KNOWN_DATE_POSTCODE] === String(this.state.authType)) && this.props.link?.tosAcceptanceRequired === true)){
                if (this.state.tos  &&  regexpPlz.test(this.state.inputPostcode)){
                    this.setState({buttonBlocked : false});
                } else {
                    this.setState({buttonBlocked : true});
                }
            }


            if (!validateBirthdate && !validatePostcode && !loginTosAccepted) {
                resolve(true);
            } 
        });
    }

    onBlur(event: any, field: string) {
        if (field === 'postcode') {
            let value = event.target.value;
            if (value !== null) {
                value = value.trim();
            }
            this.setState({ inputPostcode: value }, () => {
                this.validate(event);
            });
        } else {
            this.validate(event);
        }
    }




    pullIsTosAccepted = () => {
        if (!this?.state.tosAlreadyAccepted && this.props.link?.tosAcceptanceRequired === true) {
            console.log('pullIsTosAccepted called');
            let isTosAcceptedRequest: IsTosAcceptedRequest;
            let tosType: any = TosType[TosType.POSTFACH_NUTZUNGSBEDINGUNGEN];
            const myUUID = generateUUID();
            if (this.state.linkId) {
                isTosAcceptedRequest = {
                    correlationId: myUUID,
                    linkId: this.state.linkId,
                    tosType: tosType
                };
                LinkedappBackend.sendIsTosAccepted(isTosAcceptedRequest).then((data) => {
                    if (data !== null) {
                        if (true === data.accepted) {
                            this.setState({ tos: true, tosReadonly: true, message: null, tosAlreadyAccepted: true });
                            /*
                            const loginTosAcceptedInput = document.getElementById('loginTosAccepted') as HTMLInputElement;
                            if (loginTosAcceptedInput){
                                loginTosAcceptedInput.checked = true;
                            }
                            */
                        } else {
                            this.setState({ tosAlreadyAccepted: false });
                        }
                    }
                }).catch((error) => {
                    log("Error: " + error);
                    this.setState({ tosAlreadyAccepted: false });
                });
            }
        }
    }



    componentDidMount() {

        if (AuthType[AuthType.KNOWN_DATE_POSTCODE] === String(this.state.authType) && this.state.KNOWN_DATE_POSTCODE_QUERY) {
            const event = {
                target: {
                    value: 'postcode',
                },
            } as ChangeEvent<HTMLInputElement>;
            this.setState({ inputPostcode: this.state.KNOWN_DATE_POSTCODE_QUERY }, () => {
                this.validate(event);
                weiterbuttonMitVorausgefuelltenPlz();

            });
        }

        if (this.props.link?.tosAcceptanceRequired === true) {
            this.pullIsTosAccepted();
            this.intervalId = setInterval(this.pullIsTosAccepted, 2000);
        }
    }


    componentWillUnmount() {
        // Cleanup-Funktion für das Aufräumen bei Komponenten-Unmount
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }



    getNutzungsbedingungenUrl() {
        if (getCustomizingID(window.location.href) === CID_RHION) {
            return process.env.REACT_APP_RHION_NUTZUNGSBEDINGUNGEN;
        } else {
            return process.env.REACT_APP_RHEINLAND_NUTZUNGSBEDINGUNGEN;
        }
    }


    getAuthBlock() {

        if (AuthType[AuthType.KNOWN_DATE_POSTCODE] === String(this.state.authType) && this.props.link?.tosAcceptanceRequired === true) {
            return (
                <Box>
                    <Grid container spacing={3} >
                        <Grid item xs={12} className="answer-element" sx={{
                            backgroundColor: 'inherit',
                            borderBottom: '1px solid',
                            borderBottomColor: '#ffffff',
                            borderLeft: '1px solid #ffffff42',
                            padding: '1rem',

                            background: this.props.appState?.customizing?.colors?.decentBackground || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                            "&:nth-child(even)": {
                                background: this.props.appState?.customizing?.elements?.backgroundEven || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                            },
                            "&:nth-child(odd)": {
                                background: this.props.appState?.customizing?.elements?.backgroundOdd || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                            },
                            ...this.props.appState?.customizing?.elements?.styleOverrides ?? {},

                        }}
                        >
                            <b>Bitte teilen Sie uns Ihre Postleitzahl mit. Sie dient zum Abgleich Ihrer Identität und damit zu Ihrer Sicherheit.</b><br /><br />
                            <TextField label="Ihre Postleitzahl" type="text" value={this.state.inputPostcode} required={true}
                                onChange={(e) => this.handleChange(e, 'postcode')} onBlur={(e) => this.onBlur(e, 'postcode')} id="postcode"
                                error={(this.state.postcodeError && this.state.postcodeError !== null) || false} helperText={this.state.postcodeError} size="medium"
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>
                        <Grid item xs={12} className="answer-element" sx={{
                            backgroundColor: 'inherit',
                            borderBottom: '1px solid',
                            borderBottomColor: '#ffffff',
                            borderLeft: '1px solid #ffffff42',
                            padding: '1rem',

                            background: this.props.appState?.customizing?.colors?.decentBackground || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                            "&:nth-child(even)": {
                                background: this.props.appState?.customizing?.elements?.backgroundEven || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                            },
                            "&:nth-child(odd)": {
                                background: this.props.appState?.customizing?.elements?.backgroundOdd || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                            },
                            ...this.props.appState?.customizing?.elements?.styleOverrides ?? {},

                        }}>
                            <Checkbox
                                required={true}
                                checked={this.state.tos}
                                onChange={this.handleTosChange}
                                className="control-inner" color="primary" id="loginTosAccepted" name="loginTosAccepted" size="medium" disabled={this.state.tosReadonly} />
                            <span dangerouslySetInnerHTML={{ __html: "Ich akzeptiere die <a target=\"_blank\" rel=\"noreferrer\" href=\"" + this.getNutzungsbedingungenUrl() + "\">Nutzungsbedingungen</a> zum Digitalen Postfach" }}></span>

                        </Grid>
                    </Grid>
                </Box>
            );
        } else if (AuthType[AuthType.KNOWN_DATE_POSTCODE] === String(this.state.authType)) {
            return (
                <Grid container spacing={3} className="padding-x-3">
                    <Grid item xs={12}>
                        <TextField label="Ihre Postleitzahl" type="text" value={this.state.inputPostcode} required={true}
                            onChange={(e) => this.handleChange(e, 'postcode')} onBlur={(e) => this.onBlur(e, 'postcode')} id="postcode"
                            error={(this.state.postcodeError && this.state.postcodeError !== null) || false} helperText={this.state.postcodeError} size="medium"
                            inputProps={{ maxLength: 5 }}
                        />
                    </Grid>
                </Grid>
            );
        } else if (AuthType[AuthType.KNOWN_DATE_BIRTHDATE] === String(this.state.authType)) {
            return (
                <Grid container spacing={3} className="padding-x-3">
                    <Grid item xs={12}>
                        <StylesProvider injectFirst>
                            <KeyboardDatePicker
                                className="datepicker"
                                margin="normal"
                                id="birthdate"
                                onBlur={(e) => this.onBlur(e, 'birthdate')}
                                label="Ihr Geburtsdatum"
                                format="dd.MM.yyyy"
                                error={(this.state.birthdateError && this.state.birthdateError !== null) || false}
                                helperText={this.state.birthdateError}
                                required={true}
                                value={this.state.inputBirthdate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'Ihr Geburtsdatum',
                                }}
                            />
                        </StylesProvider>
                        {/*<DatePicker 
                            value={this.state.inputBirthdate}
                            onChange={this.handleDateChange}
                            renderInput={(params) => <TextField {...params} id="birthdate" onBlur={(e) => this.onBlur(e, 'birthdate')} helperText={this.state.birthdateError} error={(this.state.birthdateError && this.state.birthdateError !== null) || false} required={true} margin="normal" size="medium" label="Ihr Geburtsdatum" />}
                            label="Ihr Geburtsdatum"
                            inputFormat="dd.MM.yyyy"
                            mask="__.__.____"
                    />*/}
                    </Grid>
                </Grid>
            );
        } else if (AuthType[AuthType.KNOWN_DATE_POSTCODE_AND_BIRTHDATE] === String(this.state.authType)) {
            return (
                <Grid container spacing={3} className="padding-x-3">
                    <Grid item xs={12}>
                        <StylesProvider injectFirst>
                            <KeyboardDatePicker
                                className="datepicker"
                                margin="normal"
                                id="birthdate"
                                onBlur={(e) => this.onBlur(e, 'birthdate')}
                                label="Ihr Geburtsdatum"
                                format="dd.MM.yyyy"
                                error={(this.state.birthdateError && this.state.birthdateError !== null) || false}
                                helperText={this.state.birthdateError}
                                required={true}
                                value={this.state.inputBirthdate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'Ihr Geburtsdatum',
                                }}
                            />
                        </StylesProvider>
                        {/*<DatePicker 
                                value={this.state.inputBirthdate}
                                onChange={this.handleDateChange}
                                renderInput={(params) => <TextField {...params} id="birthdate" onBlur={(e) => this.onBlur(e, 'birthdate')} helperText={this.state.birthdateError} error={(this.state.birthdateError && this.state.birthdateError !== null) || false} required={true} margin="normal" size="medium" label="Ihr Geburtsdatum" />}
                                label="Ihr Geburtsdatum"
                                inputFormat="dd.MM.yyyy"
                                mask="__.__.____"
                        />*/}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Ihre Postleitzahl" type="text" value={this.state.inputPostcode} required={true}
                            onChange={(e) => this.handleChange(e, 'postcode')} onBlur={(e) => this.onBlur(e, 'postcode')} id="postcode"
                            error={(this.state.postcodeError && this.state.postcodeError !== null) || false} helperText={this.state.postcodeError} size="medium"
                            inputProps={{ maxLength: 5 }} />
                    </Grid>
                </Grid>
            );
        } else if (AuthType[AuthType.TAN] === String(this.state.authType)) {
            let phone: ExtendedAuthKeyValue | null = null;
            let mail: ExtendedAuthKeyValue | null = null;
            if (this.props.link?.authExtendedInfo !== null && this.props.link?.authExtendedInfo.value !== null) {
                let foundPhone: ExtendedAuthKeyValue[] = this.props.link?.authExtendedInfo.value.filter(v => String(ExtendedAuthKey[ExtendedAuthKey.CELLPHONE]) === String(v.key)) || [];
                if (foundPhone.length > 0) {
                    phone = foundPhone[0];
                }
                let foundMail: ExtendedAuthKeyValue[] = this.props.link?.authExtendedInfo.value.filter(v => String(ExtendedAuthKey[ExtendedAuthKey.EMAIL]) === String(v.key)) || [];
                if (foundMail.length > 0) {
                    mail = foundMail[0];
                }
            }

            if (AuthType[AuthType.TAN] === String(this.state.authType) && mail === null && phone === null) {
                return (
                    <Grid container spacing={3} className="padding-x-3">
                        <Grid item xs={12}>
                            {/* Temporär - Hier soll ein Knopf zur personalisierten Anmeldung rein - Falls der VN seine Post vollständig digital bekommen, kann er garnicht seine VSNR kennen.*/}
                            {getCustomizingID(window.location.href) === CID_RHION && <a href="https://digitalespostfach.rhion.ag/landing/registrieren?cid=rd">https://digitalespostfach.rhion.ag/landing/registrieren?cid=rd</a>}
                            {getCustomizingID(window.location.href) === CID_RHEINLAND && <a href="https://digitalespostfach.rhion.ag/landing/registrieren?cid=rl">https://digitalespostfach.rhion.ag/landing/registrieren?cid=rd</a>}
                        </Grid>
                    </Grid>
                );
            } else {
                return (
                    <Grid container spacing={3} className="padding-x-3">
                        <Grid item xs={12}>
                            Für diesen Link benötigen wir die Eingabe einer Transaktionsnummer (TAN) über einen zweiten Weg.
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <Box sx={{ paddingBottom: 1.5, paddingTop: 3, fontWeight: 700 }}>Wählen Sie eine Zustellungsart:</Box>
                                <RadioGroup aria-labelledby="tan-label" name="tan-label-radio-group" value={this.state.tanMethod} >
                                    {mail !== null && <FormControlLabel value={ExtendedAuthKey[ExtendedAuthKey.EMAIL]} control={<Radio value={ExtendedAuthKey[ExtendedAuthKey.EMAIL]} onChange={this.onSelectTanMethod} />} label={"Mail an " + mail.value} />}
                                    {phone !== null && <FormControlLabel value={ExtendedAuthKey[ExtendedAuthKey.CELLPHONE]} control={<Radio value={ExtendedAuthKey[ExtendedAuthKey.CELLPHONE]} onChange={this.onSelectTanMethod} />} label={"SMS an " + phone.value} />}
                                </RadioGroup>
                                {ExtendedAuthKey[ExtendedAuthKey.EMAIL] === this.state.tanMethod && <Box sx={{ paddingBottom: 1, paddingTop: 1, fontWeight: 400 }} className="fade-in">Bitte beachten Sie, dass die Zustellung der Mail in manchen Fällen bis zu einigen Minuten dauern Kann. Prüfen Sie bitte auch Ihren Spam-Ordner.</Box>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {this.state.sendTanButtonVisible && <Button type="button" endIcon={<SendIcon />} disabled={this.state.tanSending} title="Absenden" onClick={this.onSendTan} className="fade-in">TAN senden</Button>}
                            {this.state.tanSending && <LinearProgress sx={{ marginTop: 2, marginBottom: 2 }} color="secondary" />}
                            {this.state.tanSendingError !== null && <Box sx={{ color: '#ff0000', marginTop: 1 }}>{this.state.tanSendingError}</Box>}
                        </Grid>
                        {this.state.tanInputVisible && <Grid item xs={12} className="fade-in">
                            <Box >{this.state.tanSendingError != null ? "" : "Ihre TAN wurde versendet! Bitte geben Sie diese nun hier ein:"}</Box>
                            <TextField label="TAN" type="text" value={this.state.tan} onChange={this.onTanChange} required={true} sx={{ paddingTop: 1 }} />
                        </Grid>}
                    </Grid>
                );
            }
        }
    }

    getMessage() {
        return (
            <Grid container spacing={3} className="padding-0 padding-bottom-2">
                <Grid item xs={12} sx={{ margin: "none", paddingLeft: "0px" }}>
                    <b>{this.state.message}</b>
                </Grid>
            </Grid>
        );
    }

    getHtmlBlock() {
        let clean: string = (this.props.html && DOMPurify.sanitize(this.props.html, { ADD_ATTR: ['target'] })) || '';
        return <div dangerouslySetInnerHTML={{ __html: clean }}></div>;
    }



    onSubmit(event: any) {
        event.preventDefault();
        this.validate(event).then((valid) => {
            if (!valid) {
                return;
            } else {
                if (null !== this.state.link && null !== this.state.linkId) {
                    let authContext: AuthContext = { value: { value: [] } };


                    if (this.state.inputPostcode !== null && this.state.inputPostcode.length > 0) {
                        let authPostcode: AuthKeyValue;
                        let key: any = AuthKey[AuthKey.KNOWN_DATE_POSTCODE];
                        authPostcode = {
                            key: key,
                            value: this.state.inputPostcode
                        }
                        authContext.value.value.push(authPostcode);
                    }

                    if (this.state.inputBirthdate !== null) {
                        let authBirthdate: AuthKeyValue;
                        let key: any = AuthKey[AuthKey.KNOWN_DATE_BIRTHDATE];
                        let date: Date | null = this.state.inputBirthdate;
                        let value: string = '';
                        if (date) {
                            value = formatDate(date);
                            authBirthdate = {
                                key: key,
                                value: value
                            }
                            authContext.value.value.push(authBirthdate);
                        }
                    }

                    if (this.state.tan !== null && this.state.tan.length > 0) {
                        let key: any = AuthKey[AuthKey.TAN];
                        let authTan: AuthKeyValue = {
                            key: key,
                            value: this.state.tan
                        }
                        authContext.value.value.push(authTan);
                    }


                    const { tos } = this.state;
                    if (this.props.link?.tosAcceptanceRequired === true && tos && !this.state.tosAlreadyAccepted) {

                        log("Send sendTosAcceptedRequest");
                        let authPostcode: AuthKeyValue;
                        let key: any = AuthKey[AuthKey.KNOWN_DATE_POSTCODE];
                        authPostcode = {
                            key: key,
                            value: this.state.inputPostcode
                        }
                        let sendTosAcceptedRequest: SendTosAcceptedRequest;
                        let tosType: any = TosType[TosType.POSTFACH_NUTZUNGSBEDINGUNGEN];
                        const myUUID = generateUUID();
                        sendTosAcceptedRequest = {
                            authKeyValue: authPostcode,
                            correlationId: myUUID,
                            linkId: this.state.linkId,
                            tosType: tosType
                        }
                        LinkedappBackend.sendTosAccepted(sendTosAcceptedRequest).then((data) => {
                            if (data !== null) {
                                if (true === data.success) {
                                    log("ToS wurde aktzeptiert");
                                    this.setState({ tos: true, tosReadonly: true, message: null });

                                }
                            }
                        }).catch((error) => {
                            log("Error: " + error);
                            this.setState({ tosReadonly: false, message: "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunk noch einmal." });
                            return;
                        });

                    }

                    // Die Daten nach oben an das Parent weiterreichen.
                    this.setState({ finished: true });
                    this.props.updateAuthContext(authContext);

                }
            }
        });
    }

    getAnweisung() {

        let phone: ExtendedAuthKeyValue | null = null;
        let mail: ExtendedAuthKeyValue | null = null;
        if (AuthType[AuthType.TAN] === String(this.state.authType)) {
            if (this.props.link?.authExtendedInfo !== null && this.props.link?.authExtendedInfo.value !== null) {
                let foundPhone: ExtendedAuthKeyValue[] = this.props.link?.authExtendedInfo.value.filter(v => String(ExtendedAuthKey[ExtendedAuthKey.CELLPHONE]) === String(v.key)) || [];
                if (foundPhone.length > 0) {
                    phone = foundPhone[0];
                }
                let foundMail: ExtendedAuthKeyValue[] = this.props.link?.authExtendedInfo.value.filter(v => String(ExtendedAuthKey[ExtendedAuthKey.EMAIL]) === String(v.key)) || [];
                if (foundMail.length > 0) {
                    mail = foundMail[0];
                }
            }
        }


        if (this.props.link?.tosAcceptanceRequired === true) {
            return ("");
        } else if (AuthType[AuthType.TAN] === String(this.state.authType) && mail === null && phone === null ) {
            return (<b>Sie müssen sich bei unserem Digitalem Kundenpostfach registrieren, um das Dokument zu betrachten.</b>);
        } else if (AuthType[AuthType.KNOWN_DATE_POSTCODE] === String(this.state.authType)) {
            return (<b>Bitte teilen Sie uns Ihre Postleitzahl mit. Sie dient zum Abgleich Ihrer Identität und damit zu Ihrer Sicherheit.</b>);
        } else {
            return (<b>Zu Ihrer Sicherheit bitten wir Sie, uns zum Abgleich Ihrer Identität weitere Informationen mitzuteilen:</b>);
        }
    }



    render() {
        if (this.state && this.state.needsAuth === true) {
            let authblock = this.getAuthBlock();
            let message = this.getMessage();
            let anweisung = this.getAnweisung();
            let html = (this.state.message == null) ? this.getHtmlBlock() : <></>;
            if (this.state.message === null && (this.props.html === null || this.props.html === undefined || this.props.html === '')) {
                html = <Grid item xs={12} sx={{ marginBottom: 2 }}>{anweisung}</Grid>;
            }
            return (
                <form className="padding-3" onSubmit={this.onSubmit}>
                    <Grid container spacing={3} className="padding-3 padding-bottom-1">
                        {message}
                        {html}
                    </Grid>
                    {authblock}
                    <Grid container spacing={3} className="padding-bottom-3 padding-x-3 row buttonrow">
                        <Grid item xs={12}>
                            <Button type="submit" title="Absenden" disabled={this.state.buttonBlocked} id="authbuttonweiter"  >Weiter</Button>
                        </Grid>
                    </Grid>
                </form>
            );
        } else if (this.state && this.state.message && !this.state.needsAuth) {
            let message = this.getMessage();
            return ({ message });
        } else {
            return <></>
        }
    }

}

export default AuthRequired
