import { ReactNode, createContext, useState } from 'react';

/*
Notwendig um Anforderungen an die Registierung zu erfüllen. Es ist gewünscht, dass verschiedene Answer Elemente untereinander interagieren.
Würde man dies konfigurierbar machen, müsste man die Methodenaufrufe bzw. Methodennamen in die Datenbank ablegen. Dies wäre dann für andere nicht 
mehr nachvollziehbar/wartbar
*/

interface SignupProviderProps {
    children: ReactNode;
}


export type SignupContextInterface ={
    mobilnummerBestaetigt: boolean,
    setMobilnummerBestaetigt: (value: boolean) => void,
}



const defaultContextValue: SignupContextInterface = {
    mobilnummerBestaetigt: false,
    setMobilnummerBestaetigt: (value: boolean) => {},
};

export const SignupContext = createContext<SignupContextInterface>(defaultContextValue);

// Erstelle den Provider
const SignupProvider: React.FC<SignupProviderProps> = ({ children }) => {
    const [mobilnummerBestaetigt, setMobilnummerBestaetigt] = useState<boolean>(false);


    const handleSetMobilnummerBestaetigt = (value: boolean) => {
        setMobilnummerBestaetigt(value);
    };



    return (
        <SignupContext.Provider value={{ mobilnummerBestaetigt, setMobilnummerBestaetigt : handleSetMobilnummerBestaetigt}}>
            {children}
        </SignupContext.Provider>
    );
};

export default SignupProvider;